import React from "react";
import { graphql, Link } from "gatsby";
import { Fade } from "react-reveal";
import { useState } from "react";
import ReactPlayer from "react-player";
import * as styles from "./pediatric.module.scss";

const Pediatric = ({ component: { label } }) => {
  return (
    <div id={styles.pediatricPage}>
      <section className={styles.pediatricContainer} id="pediatric">
        <Fade up delay={500}>
          <div className={`${styles.colContainer} ${styles.mb5vw}`}>
            <div className={styles.col}>
              <div className={styles.headlineBox}>
                <h2>
                  INTRODUCING WEST MICHIGAN'S
                  <br />
                  <strong>LARGEST PEDIATRIC DENTAL GROUP</strong>
                </h2>
              </div>
            </div>
          </div>
          <div className={styles.colContainer}>
            <div className={styles.col}>
              <h3>Attention Program Directors,</h3>
              <p>
                We're ready to take your residents on the adventure of a
                lifetime. Imagine starting their careers among a vibrant team of
                Pedodontists, working in one of a network of bustling pediatric
                practices, and supported by a dental organization focused on
                nurturing and growing their professional skills. Little Smiles
                is the perfect place for doctors who want a rewarding,
                passion-filled journey filled with laughter, learning, and
                collaboration.
              </p>
            </div>
          </div>
          <div className={styles.colContainer}>
            <div className={styles.col}>
              <p>
                <strong>Little Smiles Pediatric Dental Group</strong> is an
                affiliate of{" "}
                <a href="https://gen4dental.com/about" target="_blank">
                  Gen4 Dental Partners
                </a>
                . The Little Smiles network includes{" "}
                <strong>multiple pediatric practices</strong> throughout West
                Michigan along with a{" "}
                <strong>dedicated pediatric surgical center</strong>. The
                surgical center delivers pediatric dentistry under general
                anesthesia <strong>5 DAYS A WEEK</strong>, allowing our
                pediatric dentists to work up to 6 one-hour surgical cases per
                day.
              </p>
            </div>
            <div className={styles.col}>
              {/* <img
                src="/Daydreams-PC-exterior-min.jpg"
                alt="image of dental practice"
                className={styles.pediatricShowImg}
              /> */}
              <div className={styles.media}>
                <div className={styles.videoContainer}>
                  <ReactPlayer
                    width="100%"
                    height="100%"
                    controls
                    className={styles.reactPlayer}
                    url="https://www.youtube.com/watch?v=BSY8LulO8lE"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`${styles.colContainer} ${styles.mb5vw}`}>
            <div className={styles.col}>
              <p>
                Our team of Pediatric Dentists are the heart and soul of{" "}
                <a
                  href="https://mcusercontent.com/1185627b825ecf9ef2a37ef0f/files/ad671ac8-e432-e681-aae3-9bdb5531252b/Gen4_Collaborative_Doctor_Model_v4.01.pdf"
                  target="_blank"
                >
                  Gen4's Doctor Collaborative Model
                </a>
                . That's why we provide one of the nation's best compensation
                plans, including 401K, malpractice, CE, and health insurance.
                We're also excited to be introducing a{" "}
                <strong>Student Loan Repayment Program</strong> as an integral
                component of our comprehensive compensation plan. This program
                is specifically designed to help students swiftly alleviate the
                burden of financial debt.
              </p>
            </div>
          </div>
          <div className={`${styles.colContainer} ${styles.mb5vw}`}>
            <div className={styles.col}>
              <div className={styles.headlineBox}>
                <h2>
                  WHY DO PEDODONTIST
                  <br />
                  <strong>CHOOSE TO WORK WITH GEN4?</strong>
                </h2>
              </div>
            </div>
          </div>
          <div className={`${styles.colContainer} ${styles.mb5vw}`}>
            <div className={styles.col}>
              <p>
                Gen4's Head of Pediatric Dentistry,{" "}
                <strong>Dr. Jenny Keen</strong>, is proud to present this
                program and share her Gen4 experience with you. You can click
                below to learn about her journey and{" "}
                <a
                  href="https://mcusercontent.com/1185627b825ecf9ef2a37ef0f/files/4496005f-7769-adf9-ad48-f60b4a597f3f/Gen4_PedsDoctor_Recruitment_Ltr_2_.02.pdf"
                  target="_blank"
                >
                  read about the Student Loan Repayment Program
                </a>{" "}
                in Dr. Keen's own words.
              </p>

              <p>
                To learn more about current Pedodontist opportunities with Gen4,
                please view our openings below. If interested, follow the
                prompts to apply.
              </p>

              <p>
                Have questions? Please contact our Talent & Acquisition team via
                email at{" "}
                <a href="mailto:hiring@gen4dental.com">hiring@gen4dental.com</a>
                .
              </p>
              <a
                className={styles.squareBtn}
                href="https://mcusercontent.com/1185627b825ecf9ef2a37ef0f/files/fdd4f248-28be-3207-59a2-3ddc43e18912/Gen4_PedsDoctor_Recruitment_Ltr_2_.03.pdf"
                target="_blank"
              >
                Learn About Our Student Loan Repayment Program
              </a>
              <a
                className={styles.squareBtn}
                href="https://mcusercontent.com/1185627b825ecf9ef2a37ef0f/files/800185f2-0243-9350-77eb-781f96914577/LS_Compensation_Package_1.pdf"
                target="_blank"
              >
                Download A PDF Of Our Pedontist Compensation Package
              </a>
            </div>
            <div className={styles.col}>
              <div className={styles.yellowBox}>
                <ul>
                  <li>
                    <strong>Student Loan Repayment:</strong>
                    <br />
                    $150-250K in Student Loan Repayments Available
                  </li>
                  <li>
                    <strong>Financial Security:</strong>
                    <br />
                    Minimum Daily Compensation of $1,400
                  </li>
                  <li>
                    <strong>Top-tier Income Potential:</strong>
                    <br />
                    Tiered Compensation Starting at 33-35%{" "}
                  </li>
                  <li>
                    <strong>Work + Life Balance:</strong>
                    <br />
                    No Operational Responsibility
                  </li>
                  <li>
                    <strong>Supportive Learning + Mentoring Environment</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            id="opportunities"
            className={`${styles.colContainer} ${styles.mb5vw}`}
          >
            <div className={styles.col}>
              <div className={styles.headlineBox}>
                <h2>
                  CURRENT OPPORTUNITIES:
                  <br />
                  <strong>GEN4 PRACTICES IN MICHIGAN</strong>
                </h2>
              </div>
            </div>
          </div>
          <div className={styles.colContainer}>
            <div className={styles.col}>
              <img
                src="/LittleSmiles_c-640x246.png"
                alt="little smiles logo"
                className={styles.pediatricShowImg}
              />
              {/* <a
                className={styles.squareBtn}
                href="https://smiledentalpartners.com/pediatric-dentistry/"
                target="_blank"
              >
                Learn About Little Smiles
              </a> */}
              <a
                href="https://smiledentalpartners.com/pediatric-dentistry/"
                target="_blank"
                className={styles.button}
              >
                <div className={styles.line} />
                <Fade right cascade delay={1000}>
                  <p className={styles.label}>Learn About Little Smiles</p>
                </Fade>
              </a>
            </div>
            <div className={styles.col}>
              <p>
                More than a name, <strong>Little Smiles</strong> is dental care
                designed just for children. With age-appropriate communication
                and a fun, child-friendly atmosphere, we strive to create a
                positive first experience.{" "}
                <strong>
                  We're seeking pediatric providers at TWO of our West Michigan
                  locations
                </strong>
                . Descriptions of these positions can be found below:
              </p>
              {/* <Link className={styles.button} to={button.link}>
                <div className={styles.line} />
                <Fade right cascade delay={1000}>
                  <p className={styles.label}>{button.label}</p>
                </Fade>
              </Link> */}

              {/* <a href="" target="_blank" className={styles.button}>
                <div className={styles.line} />
                <Fade right cascade delay={1000}>
                  <p className={styles.label}>
                    View Pediatric Dentist - Grand Haven, MI
                  </p>
                </Fade>
              </a>
              <a href="" target="_blank" className={styles.button}>
                <div className={styles.line} />
                <Fade right cascade delay={1000}>
                  <p className={styles.label}>
                    View Pediatric Dentist - Grand Rapids, MI
                  </p>
                </Fade>
              </a> */}
              <a
                className={styles.squareBtn}
                href="https://careers.gen4dental.com/Search/JobDetails/pediatric-dentist/61754655-d7e0-47c7-8d2b-87413484c810"
                target="_blank"
              >
                View Pediatric Dentist - Grand Haven, MI
              </a>
              {/* <a href="" target="_blank" className="styles.button">
                View Pediatric Dentist - Grand Rapids, MI
              </a> */}
            </div>
          </div>
        </Fade>
      </section>
    </div>
  );
};

export default Pediatric;

export const query = graphql`
  fragment Pediatric on PagesJson {
    components {
      label
    }
  }
`;
